import Vue from 'vue'
import VueRouter from 'vue-router'
import Indice from '../views/Indice.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Indice',
        component: Indice
    },
    {
        path: '/Medidas',
        name: 'Medidas',
        // route level code-splitting
        // this generates a separate chunk (Medidas.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Medidas" */ '../views/Medidas.vue')
    },
    {
        path: '/Selector',
        name: 'Selector',
        // route level code-splitting
        // this generates a separate chunk (Selector.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Selector" */ '../views/Selector.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
