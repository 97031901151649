<template>
  <div
    class="header"
    :class="{
      open: Open,
    }"
  >
    <div class="header__left">
      <img
        @load="OnLoadImage($event)"
        :src="RequireImage('Global/Logo.png')"
        alt=""
        class="header__left__logo"
      />
      <div class="header__left__separator"></div>
      <div class="header__left__app">
        <p>{{ aplicacion.GetLiteral(aplicacion.languageSel) }}</p>
      </div>
    </div>
    <div class="header__right">
      <div class="header__right__languages">
        <div
          v-for="(language, languageIndex) in aplicacion.languages"
          :key="language + languageIndex"
          class="header__right__languages__item"
          :class="{
            active: language == aplicacion.languageSel,
          }"
          @click="SetLanguage(language)"
        >
          {{ language }}
        </div>
      </div>
      <div class="header__right__separator"></div>
      <div class="header__right__options">
        <div
          v-for="(option, optionIndex) in aplicacion.options"
          :key="option + optionIndex"
          class="header__right__options__item"
        >
          <div
            class="header__right__options__item__option"
            @click="SetOption(option)"
          >
            <img
              @load="OnLoadImage($event)"
              :src="
                RequireImage(
                  'Global/' +
                    option.cod +
                    (aplicacion[option.cod] != undefined
                      ? aplicacion[option.cod] == false
                        ? 'On'
                        : 'Off'
                      : '') +
                    '.svg'
                )
              "
              alt=""
              class="header__right__options__item__option__image"
            />
            <div class="header__right__options__item__option__text">
              {{
                option.cod == "share"
                  ? option.GetLiteral(aplicacion.languageSel)
                  : option.literales[aplicacion[option.cod] == true ? 0 : 1][
                      aplicacion.languageSel == "fr" ? 0 : 1
                    ].texto
              }}
            </div>
          </div>
          <div class="header__right__options__item__separator"></div>
        </div>
      </div>
      <div class="header__right__hamburguer" @click="Open = !Open">
        <div class="header__right__hamburguer__item"></div>
        <div class="header__right__hamburguer__item"></div>
        <div class="header__right__hamburguer__item"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      Open: false,
    };
  },
  props: {
    aplicacion: Object,
  },
  methods: {
    SetLanguage(String) {
      if (this.aplicacion.languageSel != String) {
        const texts = document.querySelectorAll("p");
        texts.forEach((text) => {
          text.style.opacity = 0;
        });
        setTimeout(() => {
          texts.forEach((text) => {
            text.style.opacity = 1;
          });
          this.$emit("language", String);
        }, 350);
      }
    },
    SetOption(Object) {
      switch (Object.cod) {
        case "share":
          const url = new URL(window.location.href);
          const forma = this.aplicacion.GetForma().cod;
          const lados = new Array();

          this.aplicacion
            .GetForma()
            .GetSide()
            .lados.forEach((lado) => {
              lados.push({
                cod: lado.cod,
                medida: lado.medida,
                acabado: lado.GetAcabado().cod,
              });
            });

          if (forma == "l") {
            lados.length = 2;
          }

          const queryParams = new URLSearchParams({
            forma: forma,
            lados: JSON.stringify(lados),
          });
          var aux = document.createElement("input");
          aux.style.zIndex = "-9999999999999";
          aux.style.pointerEvents = "none";
          aux.style.opacity = "0";
          aux.style.position = "absolute";
          aux.setAttribute("value", url.origin + "/selector?" + queryParams);
          document.body.appendChild(aux);
          aux.select();
          document.execCommand("copy");
          document.body.removeChild(aux);
          this.$root.$emit("Toast", true);
          break;
        default:
          this.$emit("option", {
            option: Object.cod,
            value: !this.aplicacion[Object.cod],
          });
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  position: fixed;
  top: 0;
  z-index: 1000000;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  width: 100%;
  height: $Header-height;
  padding: 0px 30px;
  background-color: white;
  border-bottom: 1px solid black;
  transition: 0.35s ease-in-out background-color,
    0.35s ease-in-out border-bottom, 0.35s ease-in-out height;
  -webkit-transition: 0.35s ease-in-out background-color,
    0.35s ease-in-out border-bottom, 0.35s ease-in-out height;
  -moz-transition: 0.35s ease-in-out background-color,
    0.35s ease-in-out border-bottom, 0.35s ease-in-out height;
  -ms-transition: 0.35s ease-in-out background-color,
    0.35s ease-in-out border-bottom, 0.35s ease-in-out height;
  -o-transition: 0.35s ease-in-out background-color,
    0.35s ease-in-out border-bottom, 0.35s ease-in-out height;
  &__left {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    &__logo {
      opacity: 0;
      height: 30px;
      transition: 0.35s ease-in-out opacity;
      -webkit-transition: 0.35s ease-in-out opacity;
      -moz-transition: 0.35s ease-in-out opacity;
      -ms-transition: 0.35s ease-in-out opacity;
      -o-transition: 0.35s ease-in-out opacity;
    }
    &__separator {
      width: 2px;
      height: 22px;
      background-color: rgb(0, 0, 0, 1);
      margin: 0px 17px;
      transition: 0.35s ease-in-out background-color;
      -webkit-transition: 0.35s ease-in-out background-color;
      -moz-transition: 0.35s ease-in-out background-color;
      -ms-transition: 0.35s ease-in-out background-color;
      -o-transition: 0.35s ease-in-out background-color;
    }
    &__app {
      color: black;
      font-size: 18px;
      font-weight: 400;
      transition: 0.35s ease-in-out color, 0.35s ease-in-out opacity;
      -webkit-transition: 0.35s ease-in-out color, 0.35s ease-in-out opacity;
      -moz-transition: 0.35s ease-in-out color, 0.35s ease-in-out opacity;
      -ms-transition: 0.35s ease-in-out color, 0.35s ease-in-out opacity;
      -o-transition: 0.35s ease-in-out color, 0.35s ease-in-out opacity;
    }
  }
  &__right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    height: 30px;
    &__languages {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      align-content: center;
      &__item {
        cursor: pointer;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 15px;
        color: black;
        transition: 0.35s ease-in-out color, 0.35s ease-in-out opacity;
        -webkit-transition: 0.35s ease-in-out color, 0.35s ease-in-out opacity;
        -moz-transition: 0.35s ease-in-out color, 0.35s ease-in-out opacity;
        -ms-transition: 0.35s ease-in-out color, 0.35s ease-in-out opacity;
        -o-transition: 0.35s ease-in-out color, 0.35s ease-in-out opacity;
        &:not(:first-child) {
          margin-left: 14px;
          transition: 0.35s ease-in-out color, 0.35s ease-in-out opacity;
          -webkit-transition: 0.35s ease-in-out color, 0.35s ease-in-out opacity;
          -moz-transition: 0.35s ease-in-out color, 0.35s ease-in-out opacity;
          -ms-transition: 0.35s ease-in-out color, 0.35s ease-in-out opacity;
          -o-transition: 0.35s ease-in-out color, 0.35s ease-in-out opacity;
        }
        &.active {
          color: rgb(224, 0, 15);
          transition: 0.35s ease-in-out color, 0.35s ease-in-out opacity;
          -webkit-transition: 0.35s ease-in-out color, 0.35s ease-in-out opacity;
          -moz-transition: 0.35s ease-in-out color, 0.35s ease-in-out opacity;
          -ms-transition: 0.35s ease-in-out color, 0.35s ease-in-out opacity;
          -o-transition: 0.35s ease-in-out color, 0.35s ease-in-out opacity;
        }
        &:not(.active):hover {
          opacity: 0.35;
          transition: 0.35s ease-in-out color, 0.35s ease-in-out opacity;
          -webkit-transition: 0.35s ease-in-out color, 0.35s ease-in-out opacity;
          -moz-transition: 0.35s ease-in-out color, 0.35s ease-in-out opacity;
          -ms-transition: 0.35s ease-in-out color, 0.35s ease-in-out opacity;
          -o-transition: 0.35s ease-in-out color, 0.35s ease-in-out opacity;
        }
      }
    }
    &__separator {
      width: 2px;
      height: 22px;
      background-color: rgb(0, 0, 0);
      margin: 0px 25px;
      transition: 0.35s ease-in-out background-color;
      -webkit-transition: 0.35s ease-in-out background-color;
      -moz-transition: 0.35s ease-in-out background-color;
      -ms-transition: 0.35s ease-in-out background-color;
      -o-transition: 0.35s ease-in-out background-color;
    }
    &__options {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      align-content: center;
      &__item {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        align-content: center;
        &__option {
          cursor: pointer;
          position: relative;
          &__image {
            filter: invert(0%);
            opacity: 0;
            width: 22px;
            height: 22px;
            object-fit: cover;
            transition: 0.35s ease-in-out opacity, 0.35s ease-in-out filter;
            -webkit-transition: 0.35s ease-in-out opacity,
              0.35s ease-in-out filter;
            -moz-transition: 0.35s ease-in-out opacity, 0.35s ease-in-out filter;
            -ms-transition: 0.35s ease-in-out opacity, 0.35s ease-in-out filter;
            -o-transition: 0.35s ease-in-out opacity, 0.35s ease-in-out filter;
          }
          &__text {
            opacity: 0;
            pointer-events: none;
            position: absolute;
            top: 26px;
            right: 50%;
            background-color: rgb(0, 0, 0, 1);
            color: rgb(255, 255, 255, 1);
            font-size: 10px;
            padding: 3px 8px;
            white-space: nowrap;
            transition: 0.35s ease-in-out opacity;
            -webkit-transition: 0.35s ease-in-out opacity;
            -moz-transition: 0.35s ease-in-out opacity;
            -ms-transition: 0.35s ease-in-out opacity;
            -o-transition: 0.35s ease-in-out opacity;
          }
          &:hover {
            & .header__right__options__item__option {
              &__text {
                opacity: 1;
                transition: 0.35s ease-in-out opacity;
                -webkit-transition: 0.35s ease-in-out opacity;
                -moz-transition: 0.35s ease-in-out opacity;
                -ms-transition: 0.35s ease-in-out opacity;
                -o-transition: 0.35s ease-in-out opacity;
              }
            }
          }
        }
        &__separator {
          width: 0.1rem;
          height: 22px;
          background-color: rgb(0, 0, 0, 0);
          margin: 0px 8px;
        }
        &:last-child {
          & .header__right__options__item {
            &__separator {
              display: none;
            }
          }
        }
      }
    }
    &__hamburguer {
      display: none;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      align-content: center;
      width: 22px;
      height: 22px;
      &__item {
        transform: translate(0);
        opacity: 1;
        width: 100%;
        height: 0.1em;
        background-color: black;
        transition: 0.35s ease-in-out opacity, 0.35s ease-in-out transform,
          0.35s ease-in-out background-color;
        -webkit-transition: 0.35s ease-in-out opacity,
          0.35s ease-in-out transform, 0.35s ease-in-out background-color;
        -moz-transition: 0.35s ease-in-out opacity, 0.35s ease-in-out transform,
          0.35s ease-in-out background-color;
        -ms-transition: 0.35s ease-in-out opacity, 0.35s ease-in-out transform,
          0.35s ease-in-out background-color;
        -o-transition: 0.35s ease-in-out opacity, 0.35s ease-in-out transform,
          0.35s ease-in-out background-color;
      }
    }
  }
  @media screen and (max-width: 800px) {
    &__left {
      &__logo {
        position: absolute;
        left: 30px;
        top: 20px;
      }
      &__separator {
        display: none;
      }
      &__app {
        opacity: 0;
        font-size: 15px;
        position: absolute;
        transition: 0.35s ease-in-out color, 0.35s ease-in-out opacity;
        -webkit-transition: 0.35s ease-in-out color, 0.35s ease-in-out opacity;
        -moz-transition: 0.35s ease-in-out color, 0.35s ease-in-out opacity;
        -ms-transition: 0.35s ease-in-out color, 0.35s ease-in-out opacity;
        -o-transition: 0.35s ease-in-out color, 0.35s ease-in-out opacity;
      }
    }
    &__right {
      &__languages {
        opacity: 0;
        pointer-events: none;
        top: 24px;
        position: absolute;
        transition: 0.35s ease-in-out opacity, 0.35s ease-in-out top;
        -webkit-transition: 0.35s ease-in-out opacity, 0.35s ease-in-out top;
        -moz-transition: 0.35s ease-in-out opacity, 0.35s ease-in-out top;
        -ms-transition: 0.35s ease-in-out opacity, 0.35s ease-in-out top;
        -o-transition: 0.35s ease-in-out opacity, 0.35s ease-in-out top;
      }
      &__separator {
        display: none;
      }
      &__options {
        opacity: 0;
        pointer-events: none;
        top: 24px;
        left: 30px;
        position: absolute;
        transition: 0.35s ease-in-out opacity, 0.35s ease-in-out top;
        -webkit-transition: 0.35s ease-in-out opacity, 0.35s ease-in-out top;
        -moz-transition: 0.35s ease-in-out opacity, 0.35s ease-in-out top;
        -ms-transition: 0.35s ease-in-out opacity, 0.35s ease-in-out top;
        -o-transition: 0.35s ease-in-out opacity, 0.35s ease-in-out top;
        &__item {
          &__option {
            &__text {
              display: none;
            }
          }
        }
      }
      &__hamburguer {
        position: absolute;
        right: 30px;
        top: 24px;
        display: flex;
      }
    }
    &.open {
      height: 150px;
      transition: 0.35s ease-in-out background-color,
        0.35s ease-in-out border-bottom, 0.35s ease-in-out height;
      -webkit-transition: 0.35s ease-in-out background-color,
        0.35s ease-in-out border-bottom, 0.35s ease-in-out height;
      -moz-transition: 0.35s ease-in-out background-color,
        0.35s ease-in-out border-bottom, 0.35s ease-in-out height;
      -ms-transition: 0.35s ease-in-out background-color,
        0.35s ease-in-out border-bottom, 0.35s ease-in-out height;
      -o-transition: 0.35s ease-in-out background-color,
        0.35s ease-in-out border-bottom, 0.35s ease-in-out height;
      & .header {
        &__left {
          &__app {
            opacity: 1;
            transition: 0.35s ease-in-out color, 0.35s ease-in-out opacity;
            -webkit-transition: 0.35s ease-in-out color,
              0.35s ease-in-out opacity;
            -moz-transition: 0.35s ease-in-out color, 0.35s ease-in-out opacity;
            -ms-transition: 0.35s ease-in-out color, 0.35s ease-in-out opacity;
            -o-transition: 0.35s ease-in-out color, 0.35s ease-in-out opacity;
          }
        }
        &__right {
          &__languages {
            opacity: 1;
            top: 104px;
            pointer-events: visible;
            transition: 0.35s ease-in-out opacity, 0.35s ease-in-out top;
            -webkit-transition: 0.35s ease-in-out opacity, 0.35s ease-in-out top;
            -moz-transition: 0.35s ease-in-out opacity, 0.35s ease-in-out top;
            -ms-transition: 0.35s ease-in-out opacity, 0.35s ease-in-out top;
            -o-transition: 0.35s ease-in-out opacity, 0.35s ease-in-out top;
          }
          &__options {
            opacity: 1;
            top: 100px;
            pointer-events: visible;
            transition: 0.35s ease-in-out opacity, 0.35s ease-in-out top;
            -webkit-transition: 0.35s ease-in-out opacity, 0.35s ease-in-out top;
            -moz-transition: 0.35s ease-in-out opacity, 0.35s ease-in-out top;
            -ms-transition: 0.35s ease-in-out opacity, 0.35s ease-in-out top;
            -o-transition: 0.35s ease-in-out opacity, 0.35s ease-in-out top;
          }
          &__hamburguer {
            &__item {
              &:nth-child(1) {
                transform: translate(0, 6px) rotate(-45deg);
              }
              &:nth-child(2) {
                opacity: 0;
                transition: 0.35s ease-in-out opacity,
                  0.35s ease-in-out transform;
                -webkit-transition: 0.35s ease-in-out opacity,
                  0.35s ease-in-out transform;
                -moz-transition: 0.35s ease-in-out opacity,
                  0.35s ease-in-out transform;
                -ms-transition: 0.35s ease-in-out opacity,
                  0.35s ease-in-out transform;
                -o-transition: 0.35s ease-in-out opacity,
                  0.35s ease-in-out transform;
              }
              &:nth-child(3) {
                transform: translate(0, -6px) rotate(45deg);
              }
            }
          }
        }
      }
    }
  }
}
</style>
