import Elemento from "./Elemento.js";

export default class Forma extends Elemento {
  constructor() {
    super();
    this.objectPosition = new Object();
    this.sideSel = new String();
    this.sides = new Array();
    this.GetSide = function () {
      var side;

      var filter = this.sides.find((side) => side.cod == this.sideSel);

      filter != undefined ? (side = filter) : (side = this.sides[0]);

      return side;
    };
  }
}
