<template>
  <div
    class="toast"
    :class="{
      visible: visible,
    }"
  >
    <div class="toast__text">
      {{
        aplicacion.languageSel == "fr"
          ? "Copié dans le presse-papier"
          : aplicacion.languageSel == "en"
          ? "Copied to clipboard"
          : ""
      }}
    </div>
    <img
      @load="OnLoadImage($event)"
      :src="RequireImage('Global/Success.svg')"
      alt=""
      class="toast__image"
    />
  </div>
</template>

<script>
export default {
  name: "Toast",
  props: {
    aplicacion: Object,
  },
  mounted() {
    this.$root.$on("Toast", (Status) => {
      this.visible = Status;
      setTimeout(() => {
        this.visible = false;
      }, 2500);
    });
  },
  data() {
    return {
      visible: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.toast {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  opacity: 0;
  position: fixed;
  pointer-events: none;
  width: auto;
  height: 35px;
  background-color: #e0000f;
  top: -35px;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px 15px;
  border-radius: 5px;
  z-index: 999999999999999999;
  transition: 0.35s ease-in-out opacity, 0.35s ease-in-out top;
  -webkit-transition: 0.35s ease-in-out opacity, 0.35s ease-in-out top;
  -moz-transition: 0.35s ease-in-out opacity, 0.35s ease-in-out top;
  -ms-transition: 0.35s ease-in-out opacity, 0.35s ease-in-out top;
  -o-transition: 0.35s ease-in-out opacity, 0.35s ease-in-out top;
  &__text {
    color: white;
    font-weight: 500;
    font-size: 14px;
    white-space: nowrap;
  }
  &__image {
    filter: invert(100%);
    height: 22px;
    margin-left: 15px;
  }
  &.visible {
    opacity: 1;
    top: calc($Header-height + 25px);
    transition: 0.35s ease-in-out opacity, 0.35s ease-in-out top;
    -webkit-transition: 0.35s ease-in-out opacity, 0.35s ease-in-out top;
    -moz-transition: 0.35s ease-in-out opacity, 0.35s ease-in-out top;
    -ms-transition: 0.35s ease-in-out opacity, 0.35s ease-in-out top;
    -o-transition: 0.35s ease-in-out opacity, 0.35s ease-in-out top;
  }
}
</style>
