import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

Vue.config.productionTip = false

Vue.mixin({
  methods: {
    RequireImage(url) {
      var Image;
      try {
        Image = require('@/assets/Images/' + url)
      } catch (error) {
        Image = require('@/assets/Images/Error.svg')
        console.log('%c ' + url, 'color:red')
      }
      return Image;
    },
    OnLoadImage(e) {
      var element;
      if (e.target == null || e.target == undefined) {
        element = e.path[0];
      } else {
        element = e.target;
      }
      var miniatura = element.parentNode;
      if (miniatura.classList.contains("skeleton")) {
        miniatura.classList.remove("skeleton");
      }
      element.style.opacity = 1;
    }
  },
});

import "img-comparison-slider";
Vue.config.ignoredElements = [/img-comparison-slider/];

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
