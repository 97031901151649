<template>
  <div class="indice">
    <div class="indice__title">
      <p>
        {{
          aplicacion.languageSel == "fr"
            ? "Choix de la disposition"
            : aplicacion.languageSel == "en"
            ? "Layout choice"
            : ""
        }}
      </p>
    </div>
    <div class="indice__content">
      <div class="indice__content__formas">
        <div
          v-for="(forma, formaIndex) in aplicacion.formas"
          :key="forma + formaIndex"
          class="indice__content__formas__item"
          :class="{
            active: forma == aplicacion.GetForma(),
          }"
          @click="SetForma(forma)"
        >
          <div class="indice__content__formas__item__miniatura skeleton">
            <img
              @load="OnLoadImage($event)"
              :src="
                RequireImage(
                  'Formas/' + forma.cod + '/' + forma.cod + '_miniatura.webp'
                )
              "
              alt=""
              class="indice__content__formas__item__miniatura__image"
            />
          </div>
          <div class="indice__content__formas__item__name">
            <p>{{ forma.GetLiteral(aplicacion.languageSel) }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="indice__routes">
      <router-link class="indice__button" to="medidas">
        <div class="indice__button__text">
          <p>
            {{
              aplicacion.languageSel == "fr"
                ? "Suivant"
                : aplicacion.languageSel == "en"
                ? "Next"
                : ""
            }}
          </p>
        </div>
        <img
          @load="OnLoadImage($event)"
          :src="RequireImage('Global/Arrow.svg')"
          alt=""
          class="indice__button__image"
        />
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "Indice",
  props: {
    aplicacion: Object,
  },
  methods: {
    SetForma(Object) {
      if (this.aplicacion.GetForma() != Object) {
        this.aplicacion.formaSel = Object.cod;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.indice {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  transition: 0.35s ease-in-out background-color;
  -webkit-transition: 0.35s ease-in-out background-color;
  -moz-transition: 0.35s ease-in-out background-color;
  -ms-transition: 0.35s ease-in-out background-color;
  -o-transition: 0.35s ease-in-out background-color;
  &__title {
    color: black;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
    transition: 0.35s ease-in-out color;
    -webkit-transition: 0.35s ease-in-out color;
    -moz-transition: 0.35s ease-in-out color;
    -ms-transition: 0.35s ease-in-out color;
    -o-transition: 0.35s ease-in-out color;
  }
  &__content {
    background-color: rgb(225, 225, 225, 0.65);
    width: 100%;
    padding: 50px 0px;
    transition: 0.35s ease-in-out background-color;
    -webkit-transition: 0.35s ease-in-out background-color;
    -moz-transition: 0.35s ease-in-out background-color;
    -ms-transition: 0.35s ease-in-out background-color;
    -o-transition: 0.35s ease-in-out background-color;
    &__formas {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      align-content: center;
      &__item {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        align-content: center;
        border: 2px solid transparent;
        background-color: transparent;
        height: 350px;
        width: 225px;
        margin: 10px;
        transition: 0.35s ease-in-out border, 0.35s ease-in-out background-color;
        -webkit-transition: 0.35s ease-in-out border,
          0.35s ease-in-out background-color;
        -moz-transition: 0.35s ease-in-out border,
          0.35s ease-in-out background-color;
        -ms-transition: 0.35s ease-in-out border,
          0.35s ease-in-out background-color;
        -o-transition: 0.35s ease-in-out border,
          0.35s ease-in-out background-color;
        &__miniatura {
          width: 175px;
          height: auto;
          &__image {
            filter: brightness(100%);
            width: 100%;
            height: 100%;
            object-fit: contain;
            transition: 0.35s ease-in-out filter;
            -webkit-transition: 0.35s ease-in-out filter;
            -moz-transition: 0.35s ease-in-out filter;
            -ms-transition: 0.35s ease-in-out filter;
            -o-transition: 0.35s ease-in-out filter;
          }
          &.skeleton {
          }
        }
        &__name {
          color: black;
          transition: 0.35s ease-in-out color;
          -webkit-transition: 0.35s ease-in-out color;
          -moz-transition: 0.35s ease-in-out color;
          -ms-transition: 0.35s ease-in-out color;
          -o-transition: 0.35s ease-in-out color;
        }
        &:not(.active):hover {
          background-color: rgb(200, 200, 200, 0.35);
          transition: 0.35s ease-in-out border,
            0.35s ease-in-out background-color;
          -webkit-transition: 0.35s ease-in-out border,
            0.35s ease-in-out background-color;
          -moz-transition: 0.35s ease-in-out border,
            0.35s ease-in-out background-color;
          -ms-transition: 0.35s ease-in-out border,
            0.35s ease-in-out background-color;
          -o-transition: 0.35s ease-in-out border,
            0.35s ease-in-out background-color;
        }
        &.active {
          border: 2px solid rgb(224, 0, 15);
          background-color: rgb(224, 0, 15, 0.1);
          transition: 0.35s ease-in-out border,
            0.35s ease-in-out background-color;
          -webkit-transition: 0.35s ease-in-out border,
            0.35s ease-in-out background-color;
          -moz-transition: 0.35s ease-in-out border,
            0.35s ease-in-out background-color;
          -ms-transition: 0.35s ease-in-out border,
            0.35s ease-in-out background-color;
          -o-transition: 0.35s ease-in-out border,
            0.35s ease-in-out background-color;
        }
      }
    }
  }
  &__button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-top: 50px;
    width: 140px;
    height: 35px;
    background-color: rgb(224, 0, 15);
    text-decoration: none;
    &__text {
      text-align: center;
      min-width: 74px;
      color: white;
      font-size: 15px;
    }
    &__image {
      filter: invert(100%);
      border: 2px solid black;
      border-radius: 100%;
      margin-left: 10px;
      height: 22px;
    }
  }
  @media screen and (max-width: 500px) {
    justify-content: flex-start;
    overflow-y: overlay;
    height: calc(100dvh - $Header-height);
    &::-webkit-scrollbar {
      display: none;
    }
    &__title {
      margin: 10px 0px 10px 0px;
    }
    &__content {
      &__formas {
        flex-direction: column;
      }
    }
    &__routes {
      padding: 20px;
    }
    &__button {
      margin-top: revert;
    }
  }
  @media screen and (max-height: 700px) {
    justify-content: flex-start;
    overflow-y: overlay;
    height: calc(100dvh - $Header-height);
    &::-webkit-scrollbar {
      display: none;
    }
    &__title {
      margin: 10px 0px 10px 0px;
    }
    &__routes {
      padding: 20px;
    }
    &__button {
      margin-top: revert;
    }
  }
}
</style>
