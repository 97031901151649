import Elemento from "./Elemento.js";

export default class Lado extends Elemento {
    constructor() {
        super();
        this.acabadoSel = new String();
        this.acabados = new Array();
        this.position = new Object();
        this.medida = "";
        this.GetAcabado = function () {
            var acabado;

            var filter = this.acabados.find(
                (acabado) => acabado.cod == this.acabadoSel
            );

            filter != undefined ? acabado = filter : acabado = this.acabados[0]

            return acabado;
        };
    }
}