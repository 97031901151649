export default class Elemento {
	constructor() {
		this.cod = new String();
		this.literales = new Array();
		this.GetLiteral = function (language) {
			var literal;

			var filter = this.literales.filter((literal) => literal.language == language);

			filter.length == 1 ? literal = filter[0].texto : literal = "[" + this.cod + "]"

			return literal;
		};
	}
}


