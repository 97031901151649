var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header",class:{
    open: _vm.Open,
  }},[_c('div',{staticClass:"header__left"},[_c('img',{staticClass:"header__left__logo",attrs:{"src":_vm.RequireImage('Global/Logo.png'),"alt":""},on:{"load":function($event){return _vm.OnLoadImage($event)}}}),_c('div',{staticClass:"header__left__separator"}),_c('div',{staticClass:"header__left__app"},[_c('p',[_vm._v(_vm._s(_vm.aplicacion.GetLiteral(_vm.aplicacion.languageSel)))])])]),_c('div',{staticClass:"header__right"},[_c('div',{staticClass:"header__right__languages"},_vm._l((_vm.aplicacion.languages),function(language,languageIndex){return _c('div',{key:language + languageIndex,staticClass:"header__right__languages__item",class:{
          active: language == _vm.aplicacion.languageSel,
        },on:{"click":function($event){return _vm.SetLanguage(language)}}},[_vm._v(" "+_vm._s(language)+" ")])}),0),_c('div',{staticClass:"header__right__separator"}),_c('div',{staticClass:"header__right__options"},_vm._l((_vm.aplicacion.options),function(option,optionIndex){return _c('div',{key:option + optionIndex,staticClass:"header__right__options__item"},[_c('div',{staticClass:"header__right__options__item__option",on:{"click":function($event){return _vm.SetOption(option)}}},[_c('img',{staticClass:"header__right__options__item__option__image",attrs:{"src":_vm.RequireImage(
                'Global/' +
                  option.cod +
                  (_vm.aplicacion[option.cod] != undefined
                    ? _vm.aplicacion[option.cod] == false
                      ? 'On'
                      : 'Off'
                    : '') +
                  '.svg'
              ),"alt":""},on:{"load":function($event){return _vm.OnLoadImage($event)}}}),_c('div',{staticClass:"header__right__options__item__option__text"},[_vm._v(" "+_vm._s(option.cod == "share" ? option.GetLiteral(_vm.aplicacion.languageSel) : option.literales[_vm.aplicacion[option.cod] == true ? 0 : 1][ _vm.aplicacion.languageSel == "fr" ? 0 : 1 ].texto)+" ")])]),_c('div',{staticClass:"header__right__options__item__separator"})])}),0),_c('div',{staticClass:"header__right__hamburguer",on:{"click":function($event){_vm.Open = !_vm.Open}}},[_c('div',{staticClass:"header__right__hamburguer__item"}),_c('div',{staticClass:"header__right__hamburguer__item"}),_c('div',{staticClass:"header__right__hamburguer__item"})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }