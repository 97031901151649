<template>
  <div
    v-if="
      aplicacion.GetForma().cod == 'u'
        ? aplicacion.GetForma().GetSide().lados[0].medida != '' ||
          aplicacion.GetForma().GetSide().lados[1].medida != '' ||
          aplicacion.GetForma().GetSide().lados[2].medida != ''
        : aplicacion.GetForma().GetSide().lados[0].medida != '' ||
          aplicacion.GetForma().GetSide().lados[1].medida != ''
    "
    class="pdf"
    :class="{
      loading: Loading,
    }"
  >
    <img :src="RequireImage('Global/Loader.svg')" alt="" class="pdf__loader" />
    <vue-html2pdf
      class="pdf__generator"
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      :filename="
        aplicacion.languageSel == 'fr'
          ? 'Assistant Panneaux Muraux AKW'
          : 'AKW Wall Panel Assistant'
      "
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
      @progress="OnProgress($event)"
      @hasStartedGeneration="HasStartedGeneration()"
      @hasGenerated="HasGenerated($event)"
      ref="pdf"
    >
      <section slot="pdf-content" class="pdf__generator__content">
        <div class="pdf__generator__content__header">
          <img
            @load="OnLoadImage($event)"
            :src="RequireImage('Global/Logo.png')"
            alt=""
            class="pdf__generator__content__header__logo"
          />
          <div class="pdf__generator__content__header__separator"></div>
          <div class="pdf__generator__content__header__app">
            <p>{{ aplicacion.GetLiteral(aplicacion.languageSel) }}</p>
          </div>
        </div>
        <div class="pdf__generator__content__images">
          <img
            :src="
              RequireImage(
                'Formas/' +
                  aplicacion.GetForma().cod +
                  '/' +
                  aplicacion.GetForma().cod +
                  '_fondo.webp'
              )
            "
            alt=""
            class="pdf__generator__content__images__capa"
          />
          <img
            v-for="(capa, capaIndex) in aplicacion.GetForma().sides[0].lados"
            :key="capa + capaIndex"
            :src="
              RequireImage(
                'Formas/' +
                  aplicacion.GetForma().cod +
                  '/' +
                  aplicacion.GetForma().cod +
                  '_' +
                  capa.cod +
                  '_' +
                  capa.GetAcabado().cod +
                  '.webp'
              )
            "
            alt=""
            class="pdf__generator__content__images__capa"
          />
        </div>
        <div class="pdf__generator__content__resumen">
          <div class="pdf__generator__content__resumen__title">
            <p>
              {{
                aplicacion.languageSel == "fr"
                  ? "Résumé"
                  : aplicacion.languageSel == "en"
                  ? "Summary"
                  : ""
              }}
            </p>
          </div>
          <div class="pdf__generator__content__resumen__separador"></div>
          <div class="pdf__generator__content__resumen__lados">
            <div
              v-for="(lado, ladoIndex) in aplicacion.GetForma().GetSide().lados"
              :key="'resumen' + lado + ladoIndex"
              class="pdf__generator__content__resumen__lados__item"
            >
              <div class="pdf__generator__content__resumen__lados__item__lado">
                <div
                  class="pdf__generator__content__resumen__lados__item__lado__panel"
                >
                  <div
                    class="pdf__generator__content__resumen__lados__item__lado__panel__number"
                  >
                    {{ lado.cod }}
                  </div>
                  <div
                    class="pdf__generator__content__resumen__lados__item__lado__panel__info"
                  >
                    <div
                      class="pdf__generator__content__resumen__lados__item__lado__panel__info__title"
                    >
                      <p>
                        {{
                          aplicacion.languageSel == "fr"
                            ? "Panneau"
                            : aplicacion.languageSel == "en"
                            ? "Panel"
                            : ""
                        }}
                      </p>
                    </div>
                    <div
                      class="pdf__generator__content__resumen__lados__item__lado__panel__info__acabado"
                    >
                      <p>
                        {{
                          lado.GetAcabado().GetLiteral(aplicacion.languageSel)
                        }}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  class="pdf__generator__content__resumen__lados__item__lado__miniatura"
                >
                  <img
                    @load="OnLoadImage($event)"
                    :src="
                      RequireImage('Tacos/' + lado.GetAcabado().cod + '.webp')
                    "
                    alt=""
                    class="pdf__generator__content__resumen__lados__item__lado__miniatura__image"
                  />
                </div>
              </div>
              <div
                class="pdf__generator__content__resumen__lados__item__medidas"
              >
                <div
                  class="pdf__generator__content__resumen__lados__item__medidas__title"
                >
                  <div
                    class="pdf__generator__content__resumen__lados__item__medidas__title__lado"
                  >
                    <p>
                      {{
                        aplicacion.languageSel == "fr"
                          ? "Cóte " + lado.cod
                          : aplicacion.languageSel == "en"
                          ? "Measure " + lado.cod
                          : ""
                      }}
                    </p>
                  </div>
                  <div
                    class="pdf__generator__content__resumen__lados__item__medidas__title__medida"
                  >
                    {{ lado.medida }} mm
                  </div>
                </div>
                <div
                  v-if="lado.medida <= 1200"
                  style="margin-top: 15px"
                  class="pdf__generator__content__resumen__lados__item__medidas__paneles__item"
                >
                  <div
                    class="pdf__generator__content__resumen__lados__item__medidas__paneles__item__name"
                  >
                    <p>
                      {{
                        aplicacion.languageSel == "fr"
                          ? "Panneau 1"
                          : aplicacion.languageSel == "en"
                          ? "Panel 1"
                          : ""
                      }}
                    </p>
                    <p
                      class="pdf__generator__content__resumen__lados__item__medidas__paneles__item__name__codigo"
                    >
                      {{
                        aplicacion.GetCodigo(lado.GetAcabado().cod, 1200).cod
                      }}
                    </p>
                  </div>
                  <div
                    class="pdf__generator__content__resumen__lados__item__medidas__paneles__item__medida"
                  >
                    1200 mm
                  </div>
                </div>
                <div
                  v-else
                  class="pdf__generator__content__resumen__lados__item__medidas__paneles"
                >
                  <div
                    v-for="(medida870, medida870Index) in GetArray900(
                      lado.medida
                    )[0]"
                    :key="'870' + medida870 + medida870Index"
                    class="pdf__generator__content__resumen__lados__item__medidas__paneles__item"
                  >
                    <div
                      class="pdf__generator__content__resumen__lados__item__medidas__paneles__item__name"
                    >
                      <p>
                        {{
                          aplicacion.languageSel == "fr"
                            ? "Panneau " + (medida870Index + 1)
                            : aplicacion.languageSel == "en"
                            ? "Panel " + (medida870Index + 1)
                            : ""
                        }}
                      </p>
                      <p
                        class="pdf__generator__content__resumen__lados__item__medidas__paneles__item__name__codigo"
                      >
                        {{
                          aplicacion.GetCodigo(lado.GetAcabado().cod, 900).cod
                        }}
                      </p>
                    </div>
                    <div
                      class="pdf__generator__content__resumen__lados__item__medidas__paneles__item__medida"
                    >
                      870 mm
                    </div>
                  </div>
                  <div
                    v-for="(medida570, medida570Index) in GetArray600(
                      GetArray900(lado.medida)[1]
                    )"
                    :key="'570' + medida570 + medida570Index"
                    class="pdf__generator__content__resumen__lados__item__medidas__paneles__item"
                  >
                    <div
                      class="pdf__generator__content__resumen__lados__item__medidas__paneles__item__name"
                    >
                      <p>
                        {{
                          aplicacion.languageSel == "fr"
                            ? "Panneau " +
                              (medida570Index +
                                1 +
                                Math.floor(lado.medida / 870))
                            : aplicacion.languageSel == "en"
                            ? "Panel " +
                              (medida570Index +
                                1 +
                                Math.floor(lado.medida / 870))
                            : ""
                        }}
                      </p>
                      <p
                        class="pdf__generator__content__resumen__lados__item__medidas__paneles__item__name__codigo"
                      >
                        {{
                          aplicacion.GetCodigo(lado.GetAcabado().cod, 600).cod
                        }}
                      </p>
                    </div>
                    <div
                      class="pdf__generator__content__resumen__lados__item__medidas__paneles__item__medida"
                    >
                      570 mm
                    </div>
                  </div>
                </div>
                <div
                  v-if="aplicacion.GetForma().cod == 'u'"
                  class="pdf__generator__content__resumen__lados__item__medidas__profiles"
                >
                  <div
                    v-if="lado.cod == '1' || lado.cod == '3'"
                    class="pdf__generator__content__resumen__lados__item__medidas__profiles__item"
                  >
                    <div
                      class="pdf__generator__content__resumen__lados__item__medidas__profiles__item__name"
                    >
                      <p>
                        {{
                          aplicacion.languageSel == "fr"
                            ? "Profile de finition"
                            : aplicacion.languageSel == "en"
                            ? "Finishing profile"
                            : ""
                        }}
                      </p>
                      <p
                        class="pdf__generator__content__resumen__lados__item__medidas__paneles__item__name__codigo"
                      >
                        27730
                      </p>
                    </div>
                    <div
                      class="pdf__generator__content__resumen__lados__item__medidas__profiles__item__value"
                    >
                      <p>
                        {{
                          aplicacion.languageSel == "fr"
                            ? "Requis"
                            : aplicacion.languageSel == "en"
                            ? "Required"
                            : ""
                        }}
                      </p>
                    </div>
                  </div>
                  <div
                    v-if="lado.cod == 2"
                    class="pdf__generator__content__resumen__lados__item__medidas__profiles__item"
                  >
                    <div
                      class="pdf__generator__content__resumen__lados__item__medidas__profiles__item__name"
                    >
                      <p>
                        {{
                          aplicacion.languageSel == "fr"
                            ? "Profile d´angle"
                            : aplicacion.languageSel == "en"
                            ? "Corner profile"
                            : ""
                        }}
                      </p>
                      <p
                        class="pdf__generator__content__resumen__lados__item__medidas__paneles__item__name__codigo"
                      >
                        27736
                      </p>
                    </div>
                    <div
                      class="pdf__generator__content__resumen__lados__item__medidas__profiles__item__value"
                    >
                      <p>
                        {{
                          aplicacion.languageSel == "fr"
                            ? "Requis"
                            : aplicacion.languageSel == "en"
                            ? "Required"
                            : ""
                        }}
                      </p>
                    </div>
                  </div>
                  <div
                    v-if="lado.cod == 2"
                    class="pdf__generator__content__resumen__lados__item__medidas__profiles__item"
                  >
                    <div
                      class="pdf__generator__content__resumen__lados__item__medidas__profiles__item__name"
                    >
                      <p>
                        {{
                          aplicacion.languageSel == "fr"
                            ? "Profile d´angle"
                            : aplicacion.languageSel == "en"
                            ? "Corner profile"
                            : ""
                        }}
                      </p>
                      <p
                        class="pdf__generator__content__resumen__lados__item__medidas__paneles__item__name__codigo"
                      >
                        27736
                      </p>
                    </div>
                    <div
                      class="pdf__generator__content__resumen__lados__item__medidas__profiles__item__value"
                    >
                      <p>
                        {{
                          aplicacion.languageSel == "fr"
                            ? "Requis"
                            : aplicacion.languageSel == "en"
                            ? "Required"
                            : ""
                        }}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  v-if="aplicacion.GetForma().cod == 'l'"
                  class="pdf__generator__content__resumen__lados__item__medidas__profiles"
                >
                  <div
                    v-if="lado.cod == '1'"
                    class="pdf__generator__content__resumen__lados__item__medidas__profiles__item"
                  >
                    <div
                      class="pdf__generator__content__resumen__lados__item__medidas__profiles__item__name"
                    >
                      <p>
                        {{
                          aplicacion.languageSel == "fr"
                            ? "Profile de finition"
                            : aplicacion.languageSel == "en"
                            ? "Finishing profile"
                            : ""
                        }}
                      </p>
                      <p
                        class="pdf__generator__content__resumen__lados__item__medidas__paneles__item__name__codigo"
                      >
                        27730
                      </p>
                    </div>
                    <div
                      class="pdf__generator__content__resumen__lados__item__medidas__profiles__item__value"
                    >
                      <p>
                        {{
                          aplicacion.languageSel == "fr"
                            ? "Requis"
                            : aplicacion.languageSel == "en"
                            ? "Required"
                            : ""
                        }}
                      </p>
                    </div>
                  </div>
                  <div
                    v-if="lado.cod == 2"
                    class="pdf__generator__content__resumen__lados__item__medidas__profiles__item"
                  >
                    <div
                      class="pdf__generator__content__resumen__lados__item__medidas__profiles__item__name"
                    >
                      <p>
                        {{
                          aplicacion.languageSel == "fr"
                            ? "Profile d´angle"
                            : aplicacion.languageSel == "en"
                            ? "Corner profile"
                            : ""
                        }}
                      </p>
                      <p
                        class="pdf__generator__content__resumen__lados__item__medidas__paneles__item__name__codigo"
                      >
                        27736
                      </p>
                    </div>
                    <div
                      class="pdf__generator__content__resumen__lados__item__medidas__profiles__item__value"
                    >
                      <p>
                        {{
                          aplicacion.languageSel == "fr"
                            ? "Requis"
                            : aplicacion.languageSel == "en"
                            ? "Required"
                            : ""
                        }}
                      </p>
                    </div>
                  </div>
                  <div
                    v-if="lado.cod == '2'"
                    class="pdf__generator__content__resumen__lados__item__medidas__profiles__item"
                  >
                    <div
                      class="pdf__generator__content__resumen__lados__item__medidas__profiles__item__name"
                    >
                      <p>
                        {{
                          aplicacion.languageSel == "fr"
                            ? "Profile de finition"
                            : aplicacion.languageSel == "en"
                            ? "Finishing profile"
                            : ""
                        }}
                      </p>
                      <p
                        class="pdf__generator__content__resumen__lados__item__medidas__paneles__item__name__codigo"
                      >
                        27730
                      </p>
                    </div>
                    <div
                      class="pdf__generator__content__resumen__lados__item__medidas__profiles__item__value"
                    >
                      <p>
                        {{
                          aplicacion.languageSel == "fr"
                            ? "Requis"
                            : aplicacion.languageSel == "en"
                            ? "Required"
                            : ""
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";

export default {
  name: "Pdf",
  data() {
    return {
      Loading: false,
    };
  },
  props: {
    aplicacion: Object,
  },
  components: {
    VueHtml2pdf,
  },
  mounted() {
    this.DownloadPDF();
  },
  methods: {
    GetArray900(medidaStr) {
      var medida = parseInt(medidaStr);
      var panel = 870;
      var times = 0;

      while (medida >= 600) {
        medida -= panel;
        times++;
      }
      return [times, medida];
    },
    GetArray600(medida) {
      var panel = 570;
      var times = 0;

      if (medida > 0) {
        while (medida > 0) {
          medida -= panel;
          times++;
        }
      }

      return times;
    },
    DownloadPDF() {
      this.$root.$on("DownloadPDF", () => {
        this.GeneratePDF();
      });
    },
    GeneratePDF() {
      this.Loading = true;
      this.$refs.pdf.generatePdf();
    },
    OnProgress(e) {
      if (e == 100) {
        this.Loading = false;
      }
    },
    HasStartedGeneration() {},
    HasGenerated(e) {},
  },
};
</script>

<style lang="scss" scoped>
.pdf {
  &__loader {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 100px;
    z-index: 1000;
    transition: 0.35s ease-in-out opacity;
    -webkit-transition: 0.35s ease-in-out opacity;
    -moz-transition: 0.35s ease-in-out opacity;
    -ms-transition: 0.35s ease-in-out opacity;
    -o-transition: 0.35s ease-in-out opacity;
  }
  &__generator {
    &__content {
      &__header {
        width: 100%;
        height: $Header-height;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        align-content: center;
        padding: 0px 30px;
        background-color: white;
        border-bottom: 1px solid black;
        &__logo {
          opacity: 1;
          height: 30px;
          transition: 0.35s ease-in-out opacity;
          -webkit-transition: 0.35s ease-in-out opacity;
          -moz-transition: 0.35s ease-in-out opacity;
          -ms-transition: 0.35s ease-in-out opacity;
          -o-transition: 0.35s ease-in-out opacity;
        }
        &__separator {
          width: 2px;
          height: 22px;
          background-color: rgb(0, 0, 0, 1);
          margin: 0px 17px;
          transition: 0.35s ease-in-out background-color;
          -webkit-transition: 0.35s ease-in-out background-color;
          -moz-transition: 0.35s ease-in-out background-color;
          -ms-transition: 0.35s ease-in-out background-color;
          -o-transition: 0.35s ease-in-out background-color;
        }
        &__app {
          color: black;
          font-size: 18px;
          font-weight: 400;
          transition: 0.35s ease-in-out color;
          -webkit-transition: 0.35s ease-in-out color;
          -moz-transition: 0.35s ease-in-out color;
          -ms-transition: 0.35s ease-in-out color;
          -o-transition: 0.35s ease-in-out color;
        }
      }
      &__images {
        position: relative;
        width: 100%;
        height: 455px;
        &__capa {
          position: absolute;
          width: 100%;
          height: auto;
          object-fit: cover;
        }
      }
      &__resumen {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 597px;
        background-color: white;
        transition: 0.35s ease-in-out background-color;
        -webkit-transition: 0.35s ease-in-out background-color;
        -moz-transition: 0.35s ease-in-out background-color;
        -ms-transition: 0.35s ease-in-out background-color;
        -o-transition: 0.35s ease-in-out background-color;
        &__title {
          color: black;
          width: 100%;
          padding: 30px 30px 10px 30px;
          font-size: 20px;
          transition: 0.35s ease-in-out color;
          -webkit-transition: 0.35s ease-in-out color;
          -moz-transition: 0.35s ease-in-out color;
          -ms-transition: 0.35s ease-in-out color;
          -o-transition: 0.35s ease-in-out color;
        }
        &__separador {
          width: calc(100% - calc(30px * 2));
          height: 1.5px;
          background-color: rgb(0, 0, 0, 1);
          transition: 0.35s ease-in-out background-color;
          -webkit-transition: 0.35s ease-in-out background-color;
          -moz-transition: 0.35s ease-in-out background-color;
          -ms-transition: 0.35s ease-in-out background-color;
          -o-transition: 0.35s ease-in-out background-color;
        }
        &__lados {
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          align-items: flex-start;
          width: 100%;
          height: 100%;
          padding: 0px 30px 30px 30px;
          &__item {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            height: auto;
            padding: 30px 0px;
            transition: 0.35s ease-in-out border-bottom;
            -webkit-transition: 0.35s ease-in-out border-bottom;
            -moz-transition: 0.35s ease-in-out border-bottom;
            -ms-transition: 0.35s ease-in-out border-bottom;
            -o-transition: 0.35s ease-in-out border-bottom;
            &:nth-child(2) {
              padding-left: 30px;
            }
            &:nth-child(3) {
              padding-left: 30px;
            }
            &__lado {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: flex-start;
              align-content: center;
              width: 100%;
              height: auto;
              &__panel {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                align-content: center;
                &__number {
                  font-size: 35px;
                  font-weight: 600;
                  color: rgb(224, 0, 15, 1);
                  margin-right: 12px;
                  width: 17px;
                  height: auto;
                }
                &__info {
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: flex-start;
                  align-content: center;
                  &__title {
                    color: black;
                    font-size: 13px;
                    transition: 0.35s ease-in-out color;
                    -webkit-transition: 0.35s ease-in-out color;
                    -moz-transition: 0.35s ease-in-out color;
                    -ms-transition: 0.35s ease-in-out color;
                    -o-transition: 0.35s ease-in-out color;
                  }
                  &__acabado {
                    color: black;
                    margin-top: 3px;
                    font-size: 14px;
                    text-transform: uppercase;
                    font-weight: 700;
                    transition: 0.35s ease-in-out color;
                    -webkit-transition: 0.35s ease-in-out color;
                    -moz-transition: 0.35s ease-in-out color;
                    -ms-transition: 0.35s ease-in-out color;
                    -o-transition: 0.35s ease-in-out color;
                    & p {
                      width: 50px;
                    }
                  }
                }
              }
              &__miniatura {
                width: 100px;
                height: 100px;
                border-radius: 10px;
                background-position: center center;
                background-repeat: no-repeat no-repeat;
                background-size: 75%;
                background-image: url("@/assets/Images/Global/Transparent.webp");
                transition: 0.35s ease-in-out background-image;
                -webkit-transition: 0.35s ease-in-out background-image;
                -moz-transition: 0.35s ease-in-out background-image;
                -ms-transition: 0.35s ease-in-out background-image;
                -o-transition: 0.35s ease-in-out background-image;
                &__image {
                  opacity: 1;
                  width: 100%;
                  height: 100%;
                  border-radius: 5px;
                  object-fit: cover;
                  transition: 0.35s ease-in-out opacity;
                  -webkit-transition: 0.35s ease-in-out opacity;
                  -moz-transition: 0.35s ease-in-out opacity;
                  -ms-transition: 0.35s ease-in-out opacity;
                  -o-transition: 0.35s ease-in-out opacity;
                }
                &.skeleton {
                  background-image: url("@/assets/Images/Global/Loader.svg");
                  transition: 0.35s ease-in-out background-image;
                  -webkit-transition: 0.35s ease-in-out background-image;
                  -moz-transition: 0.35s ease-in-out background-image;
                  -ms-transition: 0.35s ease-in-out background-image;
                  -o-transition: 0.35s ease-in-out background-image;
                }
              }
            }
            &__medidas {
              width: 100%;
              height: auto;
              background-color: rgb(200, 200, 200, 0.25);
              height: auto;
              min-height: 165px;
              margin-top: 20px;
              padding: 15px 30px;
              &__title {
                color: black;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                align-content: center;
                font-size: 14px;
                transition: 0.35s ease-in-out color;
                -webkit-transition: 0.35s ease-in-out color;
                -moz-transition: 0.35s ease-in-out color;
                -ms-transition: 0.35s ease-in-out color;
                -o-transition: 0.35s ease-in-out color;
                &__lado {
                  color: rgb(224, 0, 15);
                  font-weight: 700;
                }
                &__medida {
                  font-weight: 700;
                }
              }
              &__paneles {
                color: black;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                align-content: center;
                margin-top: 15px;
                transition: 0.35s ease-in-out color;
                -webkit-transition: 0.35s ease-in-out color;
                -moz-transition: 0.35s ease-in-out color;
                -ms-transition: 0.35s ease-in-out color;
                -o-transition: 0.35s ease-in-out color;
                &__item {
                  color: black;
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                  align-content: center;
                  width: 100%;
                  font-size: 14px;
                  transition: 0.35s ease-in-out color;
                  -webkit-transition: 0.35s ease-in-out color;
                  -moz-transition: 0.35s ease-in-out color;
                  -ms-transition: 0.35s ease-in-out color;
                  -o-transition: 0.35s ease-in-out color;
                  &__name {
                    &__codigo {
                      color: rgb(224, 0, 15);
                      margin-top: 2.5px;
                      font-weight: 600;
                      font-size: 12px;
                    }
                  }
                  &__medida {
                    font-weight: 700;
                  }
                  &:not(:first-child) {
                    margin-top: 7px;
                  }
                }
              }
              &__profiles {
                color: black;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                align-content: center;
                margin-top: 15px;
                transition: 0.35s ease-in-out color;
                -webkit-transition: 0.35s ease-in-out color;
                -moz-transition: 0.35s ease-in-out color;
                -ms-transition: 0.35s ease-in-out color;
                -o-transition: 0.35s ease-in-out color;
                &__item {
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                  align-content: center;
                  width: 100%;
                  font-size: 14px;
                  &__name {
                  }
                  &__value {
                    font-weight: 700;
                  }
                  &:not(:first-child) {
                    margin-top: 7px;
                  }
                }
              }
            }
            &:last-child {
              border-bottom: none;
            }
          }
        }
      }
    }
  }
  &.loading {
    & .pdf {
      &__loader {
        opacity: 1;
        transition: 0.35s ease-in-out opacity;
        -webkit-transition: 0.35s ease-in-out opacity;
        -moz-transition: 0.35s ease-in-out opacity;
        -ms-transition: 0.35s ease-in-out opacity;
        -o-transition: 0.35s ease-in-out opacity;
      }
    }
  }
}
</style>
