import Elemento from "./Elemento.js";

export default class Side extends Elemento {
  constructor() {
    super();
    this.ladoSel = new String();
    this.lados = new Array();
    this.GetLado = function () {
      var lado;

      var filter = this.lados.find((lado) => lado.cod == this.ladoSel);

      filter != undefined ? (lado = filter) : (lado = this.lados[0]);

      return lado;
    };
  }
}
