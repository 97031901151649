<template>
  <TransitionGroup name="fade">
    <Loader v-if="Loading" key="Loader" />
    <div
      v-else
      class="app"
      :class="{
        loading: Loading,
        dark: aplicacion.theme,
      }"
      key="App"
    >
      <Pdf :aplicacion="aplicacion" />
      <Toast :aplicacion="aplicacion" />
      <Header
        class="app__header"
        :aplicacion="aplicacion"
        @language="SetLanguage"
        @option="SetOption"
      />
      <Transition name="fade">
        <router-view class="app__view" :aplicacion="aplicacion" />
      </Transition>
    </div>
  </TransitionGroup>
</template>

<script>
//COMPONENTS
import Loader from "@/components/Static/Loader.vue";
import Pdf from "@/components/Static/Pdf.vue";
import Toast from "@/components/Static/Toast.vue";
import Header from "@/components/Static/Header.vue";

//BACKEND
//---------> CLASSES
import Literal from "@/assets/Backend/Classes/Literal.js";
import Elemento from "@/assets/Backend/Classes/Elemento.js";
import Option from "@/assets/Backend/Classes/Option.js";
import Aplicacion from "@/assets/Backend/Classes/Aplicacion.js";
import Forma from "@/assets/Backend/Classes/Forma.js";
import Side from "@/assets/Backend/Classes/Side.js";
import Lado from "@/assets/Backend/Classes/Lado.js";
import Acabado from "@/assets/Backend/Classes/Acabado.js";
//---------> DB
import DB from "@/assets/Backend/DB.json";

export default {
  name: "App",
  data() {
    return {
      Loading: true,
      aplicacion: new Object(),
      aplicacionCarga: new Object(),
    };
  },
  components: {
    Loader,
    Pdf,
    Toast,
    Header,
  },
  watch: {
    aplicacion: {
      handler() {
        this.SetLS();
      },
      deep: true,
    },
  },
  mounted() {
    this.SetAplicacion();
    this.GetLS();
    this.SetParams();
  },
  methods: {
    SetLS() {
      localStorage.setItem(
        "29438-akw-selector",
        JSON.stringify(this.aplicacion)
      );
    },
    GetLS() {
      var aplicacionLS = JSON.parse(localStorage.getItem("29438-akw-selector"));
      if (aplicacionLS != null) {
        this.aplicacion.languageSel = aplicacionLS.languageSel;
        this.aplicacion.hotspots = aplicacionLS.hotspots;
        this.aplicacion.theme = aplicacionLS.theme;
        this.aplicacion.formaSel = aplicacionLS.formaSel;
        this.aplicacion.formas.forEach((forma, formaIndex) => {
          forma.sides.forEach((side, sideIndex) => {
            side.lados.forEach((lado, ladoIndex) => {
              lado.medida =
                aplicacionLS.formas[formaIndex].sides[sideIndex].lados[
                  ladoIndex
                ].medida;
              lado.acabadoSel =
                aplicacionLS.formas[formaIndex].sides[sideIndex].lados[
                  ladoIndex
                ].acabadoSel;
            });
          });
        });
      }
    },
    SetParams() {
      const url = new URL(window.location.href);
      if (url.searchParams.has("forma")) {
        const formaParam = url.searchParams.get("forma");
        const ladosParam = JSON.parse(url.searchParams.get("lados"));

        this.aplicacion.formaSel = formaParam;

        this.aplicacion.GetForma().sides.forEach((side) => {
          side.lados.forEach((lado) => {
            ladosParam.forEach((ladoParam) => {
              if (ladoParam.cod == lado.cod) {
                lado.medida = ladoParam.medida;
                lado.acabadoSel = ladoParam.acabado;
              }
            });
          });
        });

        this.$router.replace("/selector");
      }
    },
    SetAplicacion() {
      this.aplicacionCarga = new Aplicacion();

      const codigos = DB.codigos;
      this.aplicacionCarga.codigos = codigos;

      //LANGUAGES
      const languages = DB.languages;
      this.aplicacionCarga.languageSel = languages[0];
      languages.forEach((languageEl) => {
        this.aplicacionCarga.languages.push(languageEl);
        //APP NAME
        var literal = new Literal();
        literal.language = languageEl;
        literal.texto =
          languageEl == "fr"
            ? "Assistant Panneaux Muraux AKW"
            : languageEl == "en"
            ? "AKW Wall Panel Assistant"
            : undefined;
        this.aplicacionCarga.literales.push(literal);
      });

      //OPTIONS
      const options = DB.options;
      this.aplicacionCarga.theme = false;
      this.aplicacionCarga.hotspots = true;
      this.aplicacionCarga.fullscreen = false;
      options.forEach((optionEl) => {
        var option = new Option();
        option.cod = optionEl;
        languages.forEach((languageEl) => {
          var literal = new Literal();
          literal.language = languageEl;
          switch (optionEl) {
            case "share":
              literal.texto =
                languageEl == "fr"
                  ? "Partager la configuration"
                  : "Share configuration";
              break;
            case "theme":
              var literalOn;
              var literalOff;
              literalOn = {
                language: "fr",
                texto:
                  languageEl == "fr"
                    ? "Supprimer le mode sombre"
                    : "Définir le mode sombre",
              };
              literalOff = {
                language: "en",
                texto:
                  languageEl == "fr" ? "Remove dark mode" : "Set dark mode",
              };
              literal = [literalOn, literalOff];
              break;
            case "hotspots":
              var literalOn;
              var literalOff;
              literalOn = {
                language: "fr",
                texto:
                  languageEl == "fr"
                    ? "Masquer les points chauds"
                    : "Afficher les points chauds",
              };
              literalOff = {
                language: "en",
                texto: languageEl == "fr" ? "Hide hotspots" : "Show hotspots",
              };
              literal = [literalOn, literalOff];
              break;
            case "fullscreen":
              var literalOn;
              var literalOff;
              literalOn = {
                language: "fr",
                texto:
                  languageEl == "fr"
                    ? "Quitter le mode plein écran"
                    : "Plein écran",
              };
              literalOff = {
                language: "en",
                texto: languageEl == "fr" ? "Exit full screen" : "Full screen",
              };
              literal = [literalOn, literalOff];
              break;
          }
          option.literales.push(literal);
        });
        this.aplicacionCarga.options.push(option);
      });

      //DATOS
      const formas = DB.formas;
      const sides = DB.sides;
      const lados = DB.lados;
      const positions = DB.positions;
      const acabados = DB.acabados;
      formas.forEach((formaEl) => {
        var forma = new Forma();
        forma.cod = formaEl;
        languages.forEach((languageEl) => {
          var literal = new Literal();
          literal.language = languageEl;
          switch (formaEl) {
            case "u":
              forma.objectPosition = {
                close: "14.5%",
                open: "0%",
              };
              literal.texto =
                languageEl == "fr"
                  ? "Disposition en U"
                  : languageEl == "en"
                  ? "U-shaped layout"
                  : "";
              break;
            case "l":
              forma.objectPosition = {
                close: "23.5%",
                open: "0%",
              };
              literal.texto =
                languageEl == "fr"
                  ? "Disposition en L"
                  : languageEl == "en"
                  ? "L-shaped layout"
                  : "";
              break;
          }
          forma.literales.push(literal);
        });
        sides.forEach((sideEl) => {
          var side = new Side();
          side.cod = sideEl;
          lados.forEach((ladoEl) => {
            if (formaEl != "l" || ladoEl != "3") {
              var lado = new Lado();
              lado.cod = ladoEl;
              positions.forEach((positionEl) => {
                if (formaEl == positionEl.forma && ladoEl == positionEl.lado) {
                  lado.position = {
                    top: positionEl.top,
                    left: positionEl.left,
                  };
                }
              });
              languages.forEach((languageEl) => {
                var literal = new Literal();
                literal.language = languageEl;
                switch (ladoEl) {
                  case "1":
                    literal.texto =
                      languageEl == "fr"
                        ? "Panneau 1"
                        : languageEl == "en"
                        ? "Panel 1"
                        : "";
                    break;
                  case "2":
                    literal.texto =
                      languageEl == "fr"
                        ? "Panneau 2"
                        : languageEl == "en"
                        ? "Panel 2"
                        : "";
                    break;
                  case "3":
                    literal.texto =
                      languageEl == "fr"
                        ? "Panneau 3"
                        : languageEl == "en"
                        ? "Panel 3"
                        : "";
                    break;
                }
                lado.literales.push(literal);
              });
              acabados.forEach((acabadoEl) => {
                var acabado = new Acabado();
                acabado.cod = acabadoEl;
                languages.forEach((languageEl) => {
                  var literal = new Literal();
                  literal.language = languageEl;
                  switch (acabadoEl) {
                    case "ardoise":
                      literal.texto =
                        languageEl == "fr"
                          ? "Ardoise"
                          : languageEl == "en"
                          ? "Slate"
                          : null;
                      break;
                    case "beton-cire":
                      literal.texto =
                        languageEl == "fr"
                          ? "Béton Ciré"
                          : languageEl == "en"
                          ? "Waxed Concrete"
                          : null;
                      break;
                    case "carbon-elements":
                      literal.texto =
                        languageEl == "fr"
                          ? "Éléments de Carbone"
                          : languageEl == "en"
                          ? "Carbon Elements"
                          : null;
                      break;
                    case "carreau-de-ciment":
                      literal.texto =
                        languageEl == "fr"
                          ? "Carreau de Ciment"
                          : languageEl == "en"
                          ? "Cement Tile"
                          : null;
                      break;
                    case "lithium-elements":
                      literal.texto =
                        languageEl == "fr"
                          ? "Éléments de Lithium"
                          : languageEl == "en"
                          ? "Lithium Elements"
                          : null;
                      break;
                    case "marbre-blanc":
                      literal.texto =
                        languageEl == "fr"
                          ? "Marbre Blanc"
                          : languageEl == "en"
                          ? "White Marble"
                          : null;
                      break;
                    case "nickel-elements":
                      literal.texto =
                        languageEl == "fr"
                          ? "Éléments de Nickel"
                          : languageEl == "en"
                          ? "Nickel Elements"
                          : null;
                      break;
                    case "quartz":
                      literal.texto =
                        languageEl == "fr"
                          ? "Quartz"
                          : languageEl == "en"
                          ? "Quartz"
                          : null;
                      break;
                    case "sable":
                      literal.texto =
                        languageEl == "fr"
                          ? "Sable"
                          : languageEl == "en"
                          ? "Sand"
                          : null;
                      break;
                    case "beton-clair":
                      literal.texto =
                        languageEl == "fr"
                          ? "Béton Clair"
                          : languageEl == "en"
                          ? "Light Concrete"
                          : null;
                      break;
                    case "bois-clair":
                      literal.texto =
                        languageEl == "fr"
                          ? "Bois Clair"
                          : languageEl == "en"
                          ? "Light Wood"
                          : null;
                      break;
                    case "bois-naturel":
                      literal.texto =
                        languageEl == "fr"
                          ? "Bois Naturel"
                          : languageEl == "en"
                          ? "Natural Wood"
                          : null;
                      break;
                    case "bois-tropical":
                      literal.texto =
                        languageEl == "fr"
                          ? "Bois Tropical"
                          : languageEl == "en"
                          ? "Tropical Wood"
                          : null;
                      break;
                    case "cobalt":
                      literal.texto =
                        languageEl == "fr"
                          ? "Cobalt"
                          : languageEl == "en"
                          ? "Cobalt"
                          : null;
                      break;
                    case "coca-glace":
                      literal.texto =
                        languageEl == "fr"
                          ? "Coca Glace"
                          : languageEl == "en"
                          ? "Ice Coca"
                          : null;
                      break;
                    case "silver":
                      literal.texto =
                        languageEl == "fr"
                          ? "Argent"
                          : languageEl == "en"
                          ? "Silver"
                          : null;
                      break;
                    case "titanium":
                      literal.texto =
                        languageEl == "fr"
                          ? "Titane"
                          : languageEl == "en"
                          ? "Titanium"
                          : null;
                      break;
                    case "zinc":
                      literal.texto =
                        languageEl == "fr"
                          ? "Zinc"
                          : languageEl == "en"
                          ? "Zinc"
                          : null;
                      break;
                    case "blanc-bonito":
                      literal.texto =
                        languageEl == "fr"
                          ? "Blanc Bonito"
                          : languageEl == "en"
                          ? "Bonito White"
                          : null;
                      break;
                    case "blanc-brillant":
                      literal.texto =
                        languageEl == "fr"
                          ? "Blanc Brillant"
                          : languageEl == "en"
                          ? "Glossy White"
                          : null;
                      break;
                    case "brume":
                      literal.texto =
                        languageEl == "fr"
                          ? "Brume"
                          : languageEl == "en"
                          ? "Haze"
                          : null;
                      break;
                    case "cristal-artique":
                      literal.texto =
                        languageEl == "fr"
                          ? "Cristal Artique"
                          : languageEl == "en"
                          ? "Arctic Crystal"
                          : null;
                      break;
                    case "desert":
                      literal.texto =
                        languageEl == "fr"
                          ? "Désert"
                          : languageEl == "en"
                          ? "Desert"
                          : null;
                      break;
                    case "flocon":
                      literal.texto =
                        languageEl == "fr"
                          ? "Flocon"
                          : languageEl == "en"
                          ? "Snowflake"
                          : null;
                      break;
                    case "marbre-chocolat":
                      literal.texto =
                        languageEl == "fr"
                          ? "Marbre Chocolat"
                          : languageEl == "en"
                          ? "Chocolate Marble"
                          : null;
                      break;
                    case "perle":
                      literal.texto =
                        languageEl == "fr"
                          ? "Perle"
                          : languageEl == "en"
                          ? "Pearl"
                          : null;
                      break;
                    default:
                      literal.texto = null;
                      break;
                  }
                  acabado.literales.push(literal);
                });
                lado.acabados.push(acabado);
              });
              side.lados.push(lado);
            }
          });
          forma.sides.push(side);
        });
        this.aplicacionCarga.formas.push(forma);
      });

      //APLICACION
      this.aplicacion = this.aplicacionCarga;
      this.Loading = false;
    },
    //SETTERS
    SetLanguage(String) {
      this.aplicacion.languageSel = String;
    },
    SetOption(Object) {
      this.aplicacion[Object.option] = Object.value;
    },
  },
};
</script>

<style lang="scss">
.app {
  opacity: 1;
  width: 100dvw;
  height: 100dvh;
  overflow: hidden;
  transition: 0.35s ease-in-out opacity;
  -webkit-transition: 0.35s ease-in-out opacity;
  -moz-transition: 0.35s ease-in-out opacity;
  -ms-transition: 0.35s ease-in-out opacity;
  -o-transition: 0.35s ease-in-out opacity;
  &__header {
    width: 100%;
    height: $Header-height;
  }
  &__view {
    width: 100%;
    height: calc(100dvh - $Header-height);
    overflow: hidden;
    margin-top: $Header-height !important;
  }
  &.dark {
    & .header {
      background-color: #282828;
      border-bottom: 1px solid white;
      transition: 0.35s ease-in-out background-color,
        0.35s ease-in-out border-bottom, 0.35s ease-in-out height;
      -webkit-transition: 0.35s ease-in-out background-color,
        0.35s ease-in-out border-bottom, 0.35s ease-in-out height;
      -moz-transition: 0.35s ease-in-out background-color,
        0.35s ease-in-out border-bottom, 0.35s ease-in-out height;
      -ms-transition: 0.35s ease-in-out background-color,
        0.35s ease-in-out border-bottom, 0.35s ease-in-out height;
      -o-transition: 0.35s ease-in-out background-color,
        0.35s ease-in-out border-bottom, 0.35s ease-in-out height;
      &__left {
        &__separator {
          background-color: white;
          transition: 0.35s ease-in-out background-color;
          -webkit-transition: 0.35s ease-in-out background-color;
          -moz-transition: 0.35s ease-in-out background-color;
          -ms-transition: 0.35s ease-in-out background-color;
          -o-transition: 0.35s ease-in-out background-color;
        }
        &__app {
          color: white;
          transition: 0.35s ease-in-out color, 0.35s ease-in-out opacity;
          -webkit-transition: 0.35s ease-in-out color, 0.35s ease-in-out opacity;
          -moz-transition: 0.35s ease-in-out color, 0.35s ease-in-out opacity;
          -ms-transition: 0.35s ease-in-out color, 0.35s ease-in-out opacity;
          -o-transition: 0.35s ease-in-out color, 0.35s ease-in-out opacity;
        }
      }
      &__right {
        &__languages {
          &__item {
            color: white;
            transition: 0.35s ease-in-out color, 0.35s ease-in-out opacity;
            -webkit-transition: 0.35s ease-in-out color,
              0.35s ease-in-out opacity;
            -moz-transition: 0.35s ease-in-out color, 0.35s ease-in-out opacity;
            -ms-transition: 0.35s ease-in-out color, 0.35s ease-in-out opacity;
            -o-transition: 0.35s ease-in-out color, 0.35s ease-in-out opacity;
            &.active {
              color: rgb(224, 0, 15);
              transition: 0.35s ease-in-out color, 0.35s ease-in-out opacity;
              -webkit-transition: 0.35s ease-in-out color,
                0.35s ease-in-out opacity;
              -moz-transition: 0.35s ease-in-out color,
                0.35s ease-in-out opacity;
              -ms-transition: 0.35s ease-in-out color, 0.35s ease-in-out opacity;
              -o-transition: 0.35s ease-in-out color, 0.35s ease-in-out opacity;
            }
          }
        }
        &__separator {
          background-color: white;
          transition: 0.35s ease-in-out background-color;
          -webkit-transition: 0.35s ease-in-out background-color;
          -moz-transition: 0.35s ease-in-out background-color;
          -ms-transition: 0.35s ease-in-out background-color;
          -o-transition: 0.35s ease-in-out background-color;
        }
        &__options {
          &__item {
            &__option {
              &__image {
                filter: invert(100%);
                transition: 0.35s ease-in-out opacity, 0.35s ease-in-out filter;
                -webkit-transition: 0.35s ease-in-out opacity,
                  0.35s ease-in-out filter;
                -moz-transition: 0.35s ease-in-out opacity,
                  0.35s ease-in-out filter;
                -ms-transition: 0.35s ease-in-out opacity,
                  0.35s ease-in-out filter;
                -o-transition: 0.35s ease-in-out opacity,
                  0.35s ease-in-out filter;
              }
              &__text {
                filter: invert(100%);
                transition: 0.35s ease-in-out opacity, 0.35s ease-in-out filter;
                -webkit-transition: 0.35s ease-in-out opacity,
                  0.35s ease-in-out filter;
                -moz-transition: 0.35s ease-in-out opacity,
                  0.35s ease-in-out filter;
                -ms-transition: 0.35s ease-in-out opacity,
                  0.35s ease-in-out filter;
                -o-transition: 0.35s ease-in-out opacity,
                  0.35s ease-in-out filter;
              }
            }
          }
        }
        &__hamburguer {
          &__item {
            background-color: white;
            transition: 0.35s ease-in-out opacity, 0.35s ease-in-out transform,
              0.35s ease-in-out background-color;
            -webkit-transition: 0.35s ease-in-out opacity,
              0.35s ease-in-out transform, 0.35s ease-in-out background-color;
            -moz-transition: 0.35s ease-in-out opacity,
              0.35s ease-in-out transform, 0.35s ease-in-out background-color;
            -ms-transition: 0.35s ease-in-out opacity,
              0.35s ease-in-out transform, 0.35s ease-in-out background-color;
            -o-transition: 0.35s ease-in-out opacity,
              0.35s ease-in-out transform, 0.35s ease-in-out background-color;
          }
        }
      }
    }
    & .indice {
      background-color: #3f3f3f;
      transition: 0.35s ease-in-out background-color;
      -webkit-transition: 0.35s ease-in-out background-color;
      -moz-transition: 0.35s ease-in-out background-color;
      -ms-transition: 0.35s ease-in-out background-color;
      -o-transition: 0.35s ease-in-out background-color;
      &__title {
        color: white;
        transition: 0.35s ease-in-out color;
        -webkit-transition: 0.35s ease-in-out color;
        -moz-transition: 0.35s ease-in-out color;
        -ms-transition: 0.35s ease-in-out color;
        -o-transition: 0.35s ease-in-out color;
      }
      &__content {
        background-color: #8b8b8b;
        transition: 0.35s ease-in-out background-color;
        -webkit-transition: 0.35s ease-in-out background-color;
        -moz-transition: 0.35s ease-in-out background-color;
        -ms-transition: 0.35s ease-in-out background-color;
        -o-transition: 0.35s ease-in-out background-color;
        &__formas {
          &__item {
            &__miniatura {
              &__image {
                filter: brightness(0.5);
                transition: 0.35s ease-in-out filter;
                -webkit-transition: 0.35s ease-in-out filter;
                -moz-transition: 0.35s ease-in-out filter;
                -ms-transition: 0.35s ease-in-out filter;
                -o-transition: 0.35s ease-in-out filter;
              }
            }
            &__name {
              color: white;
              transition: 0.35s ease-in-out color;
              -webkit-transition: 0.35s ease-in-out color;
              -moz-transition: 0.35s ease-in-out color;
              -ms-transition: 0.35s ease-in-out color;
              -o-transition: 0.35s ease-in-out color;
            }
            &:not(.active):hover {
              background-color: #717171;
              transition: 0.35s ease-in-out border,
                0.35s ease-in-out background-color;
              -webkit-transition: 0.35s ease-in-out border,
                0.35s ease-in-out background-color;
              -moz-transition: 0.35s ease-in-out border,
                0.35s ease-in-out background-color;
              -ms-transition: 0.35s ease-in-out border,
                0.35s ease-in-out background-color;
              -o-transition: 0.35s ease-in-out border,
                0.35s ease-in-out background-color;
            }
          }
        }
      }
    }
    & .medidas {
      background-color: #3f3f3f;
      transition: 0.35s ease-in-out background-color;
      -webkit-transition: 0.35s ease-in-out background-color;
      -moz-transition: 0.35s ease-in-out background-color;
      -ms-transition: 0.35s ease-in-out background-color;
      -o-transition: 0.35s ease-in-out background-color;
      &__container {
        &__medidas {
          &__title {
            color: white;
            transition: 0.35s ease-in-out color;
            -webkit-transition: 0.35s ease-in-out color;
            -moz-transition: 0.35s ease-in-out color;
            -ms-transition: 0.35s ease-in-out color;
            -o-transition: 0.35s ease-in-out color;
          }
          &__content {
            background-color: #8b8b8b;
            transition: 0.35s ease-in-out background-color;
            -webkit-transition: 0.35s ease-in-out background-color;
            -moz-transition: 0.35s ease-in-out background-color;
            -ms-transition: 0.35s ease-in-out background-color;
            -o-transition: 0.35s ease-in-out background-color;
            &__items {
              &__miniatura {
                &__image {
                  filter: brightness(0.5);
                  transition: 0.35s ease-in-out filter;
                  -webkit-transition: 0.35s ease-in-out filter;
                  -moz-transition: 0.35s ease-in-out filter;
                  -ms-transition: 0.35s ease-in-out filter;
                  -o-transition: 0.35s ease-in-out filter;
                }
              }
            }
            &__input {
              &__title {
                color: white;
                transition: 0.35s ease-in-out color;
                -webkit-transition: 0.35s ease-in-out color;
                -moz-transition: 0.35s ease-in-out color;
                -ms-transition: 0.35s ease-in-out color;
                -o-transition: 0.35s ease-in-out color;
              }
              &__item {
                background-color: #717171;
                transition: 0.35s ease-in-out border,
                  0.35s ease-in-out background-color;
                -webkit-transition: 0.35s ease-in-out border,
                  0.35s ease-in-out background-color;
                -moz-transition: 0.35s ease-in-out border,
                  0.35s ease-in-out background-color;
                -ms-transition: 0.35s ease-in-out border,
                  0.35s ease-in-out background-color;
                -o-transition: 0.35s ease-in-out border,
                  0.35s ease-in-out background-color;
              }
            }
            &__inputs {
              &__item {
                &__title {
                  color: white;
                  transition: 0.35s ease-in-out color;
                  -webkit-transition: 0.35s ease-in-out color;
                  -moz-transition: 0.35s ease-in-out color;
                  -ms-transition: 0.35s ease-in-out color;
                  -o-transition: 0.35s ease-in-out color;
                }
                &__input {
                  background-color: #717171;
                  transition: 0.35s ease-in-out border,
                    0.35s ease-in-out background-color;
                  -webkit-transition: 0.35s ease-in-out border,
                    0.35s ease-in-out background-color;
                  -moz-transition: 0.35s ease-in-out border,
                    0.35s ease-in-out background-color;
                  -ms-transition: 0.35s ease-in-out border,
                    0.35s ease-in-out background-color;
                  -o-transition: 0.35s ease-in-out border,
                    0.35s ease-in-out background-color;
                }
              }
            }
          }
        }
        &__resumen {
          &__title {
            color: white;
            transition: 0.35s ease-in-out color;
            -webkit-transition: 0.35s ease-in-out color;
            -moz-transition: 0.35s ease-in-out color;
            -ms-transition: 0.35s ease-in-out color;
            -o-transition: 0.35s ease-in-out color;
          }
          &__content {
            background-color: #8b8b8b;
            transition: 0.35s ease-in-out background-color;
            -webkit-transition: 0.35s ease-in-out background-color;
            -moz-transition: 0.35s ease-in-out background-color;
            -ms-transition: 0.35s ease-in-out background-color;
            -o-transition: 0.35s ease-in-out background-color;
            &__lados {
              &__item {
                background-color: #717171;
                transition: 0.35s ease-in-out opacity,
                  0.35s ease-in-out background-color;
                -webkit-transition: 0.35s ease-in-out opacity,
                  0.35s ease-in-out background-color;
                -moz-transition: 0.35s ease-in-out opacity,
                  0.35s ease-in-out background-color;
                -ms-transition: 0.35s ease-in-out opacity,
                  0.35s ease-in-out background-color;
                -o-transition: 0.35s ease-in-out opacity,
                  0.35s ease-in-out background-color;
                &__title {
                  color: white;
                  transition: 0.35s ease-in-out color;
                  -webkit-transition: 0.35s ease-in-out color;
                  -moz-transition: 0.35s ease-in-out color;
                  -ms-transition: 0.35s ease-in-out color;
                  -o-transition: 0.35s ease-in-out color;
                }
                &__paneles {
                  color: white;
                  transition: 0.35s ease-in-out color;
                  -webkit-transition: 0.35s ease-in-out color;
                  -moz-transition: 0.35s ease-in-out color;
                  -ms-transition: 0.35s ease-in-out color;
                  -o-transition: 0.35s ease-in-out color;
                  &__item {
                    color: white;
                    transition: 0.35s ease-in-out color;
                    -webkit-transition: 0.35s ease-in-out color;
                    -moz-transition: 0.35s ease-in-out color;
                    -ms-transition: 0.35s ease-in-out color;
                    -o-transition: 0.35s ease-in-out color;
                  }
                }
                &__profiles {
                  color: white;
                  transition: 0.35s ease-in-out color;
                  -webkit-transition: 0.35s ease-in-out color;
                  -moz-transition: 0.35s ease-in-out color;
                  -ms-transition: 0.35s ease-in-out color;
                  -o-transition: 0.35s ease-in-out color;
                }
              }
            }
          }
        }
      }
      &__text {
        color: white;
        transition: 0.35s ease-in-out color;
        -webkit-transition: 0.35s ease-in-out color;
        -moz-transition: 0.35s ease-in-out color;
        -ms-transition: 0.35s ease-in-out color;
        -o-transition: 0.35s ease-in-out color;
      }
    }
    & .buttons {
      &__item {
        background-color: #282828;
        &__image {
          filter: invert(100%);
        }
        &__text {
          filter: invert(100%);
        }
        &.active {
          background-color: #8b8b8b;
        }
      }
    }
    & .menu {
      background-color: #282828;
      transition: 0.35s ease-in-out margin-right,
        0.35s ease-in-out background-color;
      -webkit-transition: 0.35s ease-in-out margin-right,
        0.35s ease-in-out background-color;
      -moz-transition: 0.35s ease-in-out margin-right,
        0.35s ease-in-out background-color;
      -ms-transition: 0.35s ease-in-out margin-right,
        0.35s ease-in-out background-color;
      -o-transition: 0.35s ease-in-out margin-right,
        0.35s ease-in-out background-color;
      &__paneles {
        background-color: #282828;
        transition: 0.35s ease-in-out background-color;
        -webkit-transition: 0.35s ease-in-out background-color;
        -moz-transition: 0.35s ease-in-out background-color;
        -ms-transition: 0.35s ease-in-out background-color;
        -o-transition: 0.35s ease-in-out background-color;
        &__lados {
          &__item {
            background-color: #282828;
            transition: 0.35s ease-in-out background-color;
            -webkit-transition: 0.35s ease-in-out background-color;
            -moz-transition: 0.35s ease-in-out background-color;
            -ms-transition: 0.35s ease-in-out background-color;
            -o-transition: 0.35s ease-in-out background-color;
            &__text {
              color: white;
              transition: 0.35s ease-in-out color;
              -webkit-transition: 0.35s ease-in-out color;
              -moz-transition: 0.35s ease-in-out color;
              -ms-transition: 0.35s ease-in-out color;
              -o-transition: 0.35s ease-in-out color;
            }
            &__expand {
              filter: invert(100%);
              transition: 0.35s ease-in-out transform, 0.35s ease-in-out opacity,
                0.35s ease-in-out margin-top, 0.35s ease-in-out filter;
              -webkit-transition: 0.35s ease-in-out transform,
                0.35s ease-in-out opacity, 0.35s ease-in-out margin-top,
                0.35s ease-in-out filter;
              -moz-transition: 0.35s ease-in-out transform,
                0.35s ease-in-out opacity, 0.35s ease-in-out margin-top,
                0.35s ease-in-out filter;
              -ms-transition: 0.35s ease-in-out transform,
                0.35s ease-in-out opacity, 0.35s ease-in-out margin-top,
                0.35s ease-in-out filter;
              -o-transition: 0.35s ease-in-out transform,
                0.35s ease-in-out opacity, 0.35s ease-in-out margin-top,
                0.35s ease-in-out filter;
            }
          }
        }
        &__separador {
          background-color: white;
          transition: 0.35s ease-in-out background-color;
          -webkit-transition: 0.35s ease-in-out background-color;
          -moz-transition: 0.35s ease-in-out background-color;
          -ms-transition: 0.35s ease-in-out background-color;
          -o-transition: 0.35s ease-in-out background-color;
        }
        &__content {
          &__acabados {
            &__item {
              &__text {
                color: white;
                transition: 0.35s ease-in-out color;
                -webkit-transition: 0.35s ease-in-out color;
                -moz-transition: 0.35s ease-in-out color;
                -ms-transition: 0.35s ease-in-out color;
                -o-transition: 0.35s ease-in-out color;
              }
            }
          }
        }
      }
      &__resumen {
        background-color: #282828;
        transition: 0.35s ease-in-out background-color;
        -webkit-transition: 0.35s ease-in-out background-color;
        -moz-transition: 0.35s ease-in-out background-color;
        -ms-transition: 0.35s ease-in-out background-color;
        -o-transition: 0.35s ease-in-out background-color;
        &__title {
          color: white;
          transition: 0.35s ease-in-out color;
          -webkit-transition: 0.35s ease-in-out color;
          -moz-transition: 0.35s ease-in-out color;
          -ms-transition: 0.35s ease-in-out color;
          -o-transition: 0.35s ease-in-out color;
        }
        &__separador {
          background-color: white;
          transition: 0.35s ease-in-out background-color;
          -webkit-transition: 0.35s ease-in-out background-color;
          -moz-transition: 0.35s ease-in-out background-color;
          -ms-transition: 0.35s ease-in-out background-color;
          -o-transition: 0.35s ease-in-out background-color;
        }
        &__lados {
          &__item {
            border-bottom: 2px solid white;
            transition: 0.35s ease-in-out border-bottom;
            -webkit-transition: 0.35s ease-in-out border-bottom;
            -moz-transition: 0.35s ease-in-out border-bottom;
            -ms-transition: 0.35s ease-in-out border-bottom;
            -o-transition: 0.35s ease-in-out border-bottom;
            &__lado {
              &__panel {
                &__info {
                  &__title {
                    color: white;
                    transition: 0.35s ease-in-out color;
                    -webkit-transition: 0.35s ease-in-out color;
                    -moz-transition: 0.35s ease-in-out color;
                    -ms-transition: 0.35s ease-in-out color;
                    -o-transition: 0.35s ease-in-out color;
                  }
                  &__acabado {
                    color: white;
                    transition: 0.35s ease-in-out color;
                    -webkit-transition: 0.35s ease-in-out color;
                    -moz-transition: 0.35s ease-in-out color;
                    -ms-transition: 0.35s ease-in-out color;
                    -o-transition: 0.35s ease-in-out color;
                  }
                }
              }
            }
            &__medidas {
              &__title {
                color: white;
                transition: 0.35s ease-in-out color;
                -webkit-transition: 0.35s ease-in-out color;
                -moz-transition: 0.35s ease-in-out color;
                -ms-transition: 0.35s ease-in-out color;
                -o-transition: 0.35s ease-in-out color;
              }
              &__paneles {
                color: white;
                transition: 0.35s ease-in-out color;
                -webkit-transition: 0.35s ease-in-out color;
                -moz-transition: 0.35s ease-in-out color;
                -ms-transition: 0.35s ease-in-out color;
                -o-transition: 0.35s ease-in-out color;
                &__item {
                  color: white;
                  transition: 0.35s ease-in-out color;
                  -webkit-transition: 0.35s ease-in-out color;
                  -moz-transition: 0.35s ease-in-out color;
                  -ms-transition: 0.35s ease-in-out color;
                  -o-transition: 0.35s ease-in-out color;
                }
              }
              &__profiles {
                color: white;
                transition: 0.35s ease-in-out color;
                -webkit-transition: 0.35s ease-in-out color;
                -moz-transition: 0.35s ease-in-out color;
                -ms-transition: 0.35s ease-in-out color;
                -o-transition: 0.35s ease-in-out color;
              }
            }
          }
        }
      }
    }
  }
  &.loading {
    opacity: 0;
    transition: 0.35s ease-in-out opacity;
    -webkit-transition: 0.35s ease-in-out opacity;
    -moz-transition: 0.35s ease-in-out opacity;
    -ms-transition: 0.35s ease-in-out opacity;
    -o-transition: 0.35s ease-in-out opacity;
  }
}
</style>
