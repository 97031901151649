import Elemento from "./Elemento.js";

export default class Aplicacion extends Elemento {
  constructor() {
    super();
    this.codigos = new Array();
    this.languageSel = new String();
    this.languages = new Array();
    this.theme = new Boolean();
    this.hotspots = new Boolean();
    this.fullscreen = new Boolean();
    this.options = new Array();
    this.formaSel = new String();
    this.formas = new Array();
    this.GetForma = function () {
      var forma;

      var filter = this.formas.find(
        (forma) => forma.cod == this.formaSel
      );

      filter != undefined ? forma = filter : forma = this.formas[0]

      return forma;
    };
    this.GetCodigo = function (color, medida) {
      console.log(color, medida)
      var filter = this.codigos.find((codigoEl) => codigoEl.color == color && codigoEl.medida == medida);

      return (filter != undefined ? filter : null)
    };
  }
}